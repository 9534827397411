import classnames from 'classnames';
import React from 'react';

import { Is, IsComponent, Props as IsProps } from '@/component/Is';

export type Props<T extends IsComponent> = IsProps<T> & {
  invalid?: boolean;
};

export const Input = React.forwardRef(function Input<T extends IsComponent = 'input'>(
  // @ts-ignore
  { is = 'input', invalid, ...props }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) {
  return (
    // @ts-ignore
    <Is
      {...{ is, ref }}
      {...props}
      className={classnames(
        'block w-full border border-solid text-sm text-gray-900',
        { 'border-gray-200': !invalid, 'border-red-500 focus:border-solid': invalid },
        props.className
      )}
    />
  );
});
