import classnames from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Logo } from '@/component/Logo';
import { Title } from '@/component/Title';

interface Props {
  title: string;
  withLogo?: boolean;
  className?: string;
}

export const Header: React.FC<Props> = ({ title, withLogo, className, children }) => (
  <header className={classnames('relative z-10 h-[72px] flex-shrink-0 bg-white', className)}>
    <div className="border-bottom-2 fixed top-0 left-0 h-[72px] w-full bg-white shadow-md">
      <div className="flex h-full items-center justify-between px-8">
        <Helmet title={title} />

        {!!withLogo ? <Logo className="h-8" /> : <Title className="pl-12 text-[24px] text-gray-900">{title}</Title>}

        <div className="flex items-center">{children}</div>
      </div>
    </div>
  </header>
);
