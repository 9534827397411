export enum LibrarySearchHitType {
  Speech = 'speech',
  Text = 'text',
  Ocr = 'ocr'
}

export interface LibrarySearchResultResource {
  url: string;
  previewUrl: string;
}

export interface LibrarySearchResult {
  id: number;
  resources: LibrarySearchResultResource[];
  start: Date;
  imageUrl: { small: string; large: string };
  subjects: [];
  hits: {
    contextId: string;
    playbackUrl: string;
    phrase: string;
    elapsed: number;
    type: LibrarySearchHitType;
  }[];
}
