import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { urls } from '@/constant';
import * as session from '@/redux/session';

import { ButtonContainer } from '@/component/ButtonContainer';
import { Form, FormField, handleError } from '@/component/Form';
import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { Button } from '@/component/UiKit/Button';
import { Label } from '@/component/UiKit/Field/Label';
import { Link, LinkWithPersist } from '@/component/UiKit/Link';
import { TextInput } from '@/component/UiKit/TextInput';
import { ValidationMessage } from '@/component/UiKit/ValidationMessage';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required()
  })
  .required();

export const SignInPage: React.FC = () => {
  const dispatch = useDispatch();

  const onSignInWithEmail = React.useCallback(
    (values: yup.InferType<typeof schema>) => {
      return dispatch(session.signInWithEmail(values.email, values.password))
        .then(() => null)
        .catch(
          handleError({
            400: (error) => error.fields ?? error.message,
            default: () => `Invalid credentials. Check for typos and try again.`
          })
        );
    },
    [dispatch]
  );

  return (
    <IdentityPage
      title="Sign in"
      header={
        <Button is={LinkWithPersist} appearance="primary" to={urls.signUp.send}>
          Sign Up
        </Button>
      }
    >
      <Form schema={schema} onSubmit={onSignInWithEmail}>
        {({ submitError, submitting, handleSubmit }) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <IdentityTitle title="Sign in">Log In To Your Account</IdentityTitle>

            <div className="space-y-4">
              <div className="space-y-1">
                <Label htmlFor="email">Email address</Label>

                <FormField
                  is={TextInput}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="nick@email.com"
                  readOnly={submitting}
                  validated
                />
              </div>

              <div className="space-y-1">
                <Label htmlFor="password">Password</Label>

                <FormField
                  is={TextInput}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="your secure password"
                  readOnly={submitting}
                  validated
                />
              </div>

              <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

              <ButtonContainer>
                <Button is="button" appearance="primary" type="submit" loading={submitting}>
                  Log In
                </Button>

                {/* <GoogleLoginButton onSuccess={this.signInWithGoogle} /> */}
              </ButtonContainer>
            </div>

            <div className="text-sm">
              <Link is={LinkWithPersist} to={urls.forgotPassword.send}>
                Forgot password?
              </Link>
            </div>
          </form>
        )}
      </Form>
    </IdentityPage>
  );
};
