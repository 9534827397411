import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { useAsync } from '@/component/hooks/use-async';
import { useQuery } from '@/component/hooks/use-query';
import { urls } from '@/constant';
import * as session from '@/redux/session';
import { organizationService } from '@/service/business/organization';

import { ConfigurationContext } from '@/component/Context/Configuration';
import { Form, FormField, handleError } from '@/component/Form';
import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { getRedirectUrlFromParams } from '@/component/Navigation/redirect';
import { Button } from '@/component/UiKit/Button';
import { Label } from '@/component/UiKit/Field/Label';
import { Link, LinkWithPersist } from '@/component/UiKit/Link';
import { TextInput } from '@/component/UiKit/TextInput';
import { ValidationMessage } from '@/component/UiKit/ValidationMessage';

const schema = yup
  .object({
    email: yup.string().email().max(50).required()
  })
  .required();

export const SignUpSendPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { update } = React.useContext(ConfigurationContext);

  const authenticated = useSelector(session.isAuthenticated);
  const query = useQuery<{ organization: string; role: string }>();

  const organization = React.useMemo(() => {
    if (!query.organization || !query.role) return;

    return { id: +query.organization, role: query.role };
  }, [query]);

  const load = React.useCallback(() => organizationService.getConfiguration(organization!.id).then(update), []);

  const [{ loading }] = useAsync(load, !!organization);

  const onSubmit = React.useCallback((values: yup.InferType<typeof schema>) => {
    return dispatch(session.signUpWithEmail(values.email, organization))
      .then(() => history.push(`${urls.signUp.confirm}?email=${encodeURIComponent(values.email)}`))
      .then(() => null)
      .catch(
        handleError({
          400: (error) => error.fields || {},
          409: () => `An account already exists with this email address. Sign in instead.`,
          default: () => `We couldn't create your account at the moment.`
        })
      );
  }, []);

  const redirect = getRedirectUrlFromParams(location.pathname, location.search);

  if (authenticated) return <Redirect to={redirect || urls.home} />;

  if (loading) return null;

  return (
    <IdentityPage
      title="Sign up"
      header={
        <Button is={LinkWithPersist} appearance="primary" to={urls.signIn}>
          Log In
        </Button>
      }
    >
      <Form schema={schema} onSubmit={onSubmit}>
        {({ submitError, submitting, handleSubmit }) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <IdentityTitle title="Sign up">Get started with Bramble</IdentityTitle>

            <div className="space-y-4">
              <div className="space-y-1">
                <Label htmlFor="email">Email address</Label>

                <FormField
                  is={TextInput}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="nick@email.com"
                  readOnly={submitting}
                  validated
                />
              </div>

              <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

              <Button appearance="primary" type="submit" loading={submitting}>
                Sign Up
              </Button>
            </div>

            <div className="text-sm">
              <Link is={LinkWithPersist} to={urls.signIn}>
                Already have an account? Log in
              </Link>
            </div>
          </form>
        )}
      </Form>
    </IdentityPage>
  );
};
