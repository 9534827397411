import * as React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { getRedirectUrlFromParams } from '@/component/Navigation/redirect';

interface Props {
  render(): React.ReactNode;
}

export const ConsumeRedirectRoute: React.FC<Props> = (props) => {
  const location = useLocation();

  const redirectFromParam = React.useMemo(
    () => getRedirectUrlFromParams(location.pathname, location.search),
    [location]
  );

  if (redirectFromParam) {
    return <Redirect to={redirectFromParam} />;
  }

  return <Route {...props} />;
};
