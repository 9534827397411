import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constant';
import * as context from '@/redux/context';
import * as session from '@/redux/session';

import { useAsync } from '@/component/hooks/use-async';
import { ConsumeRedirectRoute } from '@/component/Navigation/ConsumeRedirectRoute';
import { RedirectWithPersist } from '@/component/Navigation/RedirectWithPersist';
import { ScrollToTop } from '@/component/ScrollToTop';
import { Loading, LoadingIndicator } from '@/component/UiKit/Loading';
import { ForgotPasswordPage } from '@/page/ForgotPasswordPage';
import { IntegrationIssuePage } from '@/page/Integration/IntegrationIssuePage';
import { SignInPage } from '@/page/SignInPage';
import { SignUpPage } from '@/page/SignUpPage';

const HomePage = React.lazy(() => import('@/page/HomePage'));

export const App: React.FC = () => {
  const authenticated = !!useSelector(session.getRefreshToken);
  const data = useSelector(context.get);
  const dispatch = useDispatch();

  const onLoad = React.useCallback(() => {
    if (!authenticated || data) return Promise.resolve();

    return dispatch(context.load());
  }, [authenticated, data, dispatch]);

  const [{ loading, error }] = useAsync(onLoad, authenticated);

  return (
    <ScrollToTop>
      <Helmet defaultTitle="Bramble" titleTemplate="%s - Bramble" />

      <Loading visible={loading} className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <LoadingIndicator className="h-8 w-8 text-gray-900" />
      </Loading>

      <React.Suspense fallback={false}>
        {!loading && !error && (
          <Switch>
            <Route path={urls.integrations.issue} component={IntegrationIssuePage} />
            <Route path={urls.forgotPassword.send} component={ForgotPasswordPage} />
            <Route
              render={() => {
                if (!authenticated)
                  return (
                    <Switch>
                      <Route path={urls.signIn} component={SignInPage} />
                      <Route path={urls.signUp.send} component={SignUpPage} />
                      <ConsumeRedirectRoute render={() => <RedirectWithPersist to={urls.signIn} />} />
                    </Switch>
                  );

                if (data)
                  return (
                    <Switch>
                      <Route path={urls.signUp.send} component={SignUpPage} />
                      <Route path={urls.home} component={HomePage} />
                      <ConsumeRedirectRoute render={() => <Redirect to={urls.home} />} />
                    </Switch>
                  );

                return null;
              }}
            />
          </Switch>
        )}
      </React.Suspense>
    </ScrollToTop>
  );
};
