import { createSelector } from 'reselect';

import { OrganizationType } from '@/constant';
import { AgreementType, Context, OrganizationRole, User } from '@/model';
import { State } from '@/redux/state';
import { Feature, features } from '@/service/features';

export const get = (state: State): Context | null => state.context;

export const getUser = createSelector([get], (context) => (context ? context.user : ({} as User)));

export const getConfiguration = createSelector([get], (context) => (context ? context.configuration : undefined));

export const getOrganizations = createSelector([get], (context) => (context ? context.organizations : []));

export const getIndividualOrganizations = createSelector([getOrganizations], (organizations) =>
  organizations.filter((organization) => organization.type === OrganizationType.Individual)
);

export const getAgencyOrganizations = createSelector([getOrganizations], (organizations) =>
  organizations.filter((organization) => organization.type === OrganizationType.Agency)
);

export const getOwnedOrganizations = createSelector([getOrganizations], (organizations) =>
  organizations.filter((organization) => organization.role === 'OWNER')
);

export const getOwnedAgencies = createSelector([getAgencyOrganizations], (organizations) =>
  organizations.filter((organization) => organization.role === 'OWNER')
);

export const getTutoringOrganizations = createSelector(
  [getIndividualOrganizations, getAgencyOrganizations],
  (individualOrganizations, agencyOrganizations) => [
    ...individualOrganizations.filter((organization) => ['OWNER'].includes(organization.role)),
    ...agencyOrganizations.filter((organization) => ['MEMBER', 'LEADTUTOR', 'OWNER'].includes(organization.role))
  ]
);

export const getTeachingOrganizations = createSelector([getAgencyOrganizations], (organizations) =>
  organizations.filter((organization) => organization.role === 'TEACHER')
);

export const getTutoringProgram = createSelector([getAgencyOrganizations], (organizations) =>
  organizations.filter((organization) => organization.role === 'TEACHER')
);

export const getUserState = createSelector([getUser], (user) => user.state);

export const hasUserState = (state: number, value: number) => (state & value) === value;

export const getOrganizationsWithTutoringProgram = createSelector([getAgencyOrganizations], (organizations) =>
  organizations.filter((organization) => organization.tutoringProgram)
);

export const hasTutoringProgram = createSelector(
  [getOrganizationsWithTutoringProgram],
  (organizations) => organizations.length > 0
);

export const hasTutoringProgramWithRole = (roles: OrganizationRole[]) =>
  createSelector([getOrganizationsWithTutoringProgram], (organizations) =>
    organizations.some((organization) => roles.includes(organization.role))
  );

export const getOrganizationsWithRole = (roles: OrganizationRole[]) =>
  createSelector([getOrganizations], (organizations) =>
    organizations.filter((organization) => roles.includes(organization.role))
  );

export const isTeacher = createSelector([getOrganizations], (organizations) =>
  organizations.some((organization) => organization.role === 'TEACHER')
);

export const isTutor = createSelector(
  [getTutoringOrganizations],
  (tutoringOrganizations) => tutoringOrganizations.length > 0
);

export const isStudent = createSelector(
  [getOrganizations],
  (organizations) => !organizations.some((organization) => organization.role !== 'STUDENT')
);

export const shouldSelectSchool = createSelector([getOrganizations], (organizations) =>
  organizations.some((organization) => organization.role === 'TEACHER' && !organization.schoolIds.length)
);

export const hasPermissionEditOrganizationSettings = createSelector(
  [getOwnedOrganizations],
  (organizations) => organizations.length > 0
);

export const getOrganizationsWithManageRole = getOrganizationsWithRole([
  OrganizationRole.Owner,
  OrganizationRole.LeadTutor,
  OrganizationRole.Teacher,
  OrganizationRole.Member
]);

export const hasPermissionCreateTutorProfile = hasTutoringProgramWithRole([
  OrganizationRole.LeadTutor,
  OrganizationRole.Member
]);
export const hasPermissionViewJobs = hasTutoringProgramWithRole([
  OrganizationRole.Owner,
  OrganizationRole.LeadTutor,
  OrganizationRole.Teacher,
  OrganizationRole.Member
]);
export const hasPermissionCreateJob = hasTutoringProgramWithRole([OrganizationRole.Teacher]);
export const hasPermissionViewJobApplications = hasTutoringProgramWithRole([
  OrganizationRole.Owner,
  OrganizationRole.LeadTutor,
  OrganizationRole.Teacher
]);
export const hasPermissionApplyJob = hasTutoringProgramWithRole([OrganizationRole.Member]);
export const hasPermissionConfirmJob = hasTutoringProgramWithRole([OrganizationRole.Owner, OrganizationRole.Teacher]);
export const hasPermissionRescheduleJob = hasTutoringProgramWithRole([
  OrganizationRole.Owner,
  OrganizationRole.Teacher
]);
export const hasPermissionCloneJob = hasTutoringProgramWithRole([OrganizationRole.Owner, OrganizationRole.Teacher]);
export const hasPermissionCancelJob = hasTutoringProgramWithRole([OrganizationRole.Owner, OrganizationRole.Teacher]);
export const hasPermissionUpdateJobStudents = hasTutoringProgramWithRole([
  OrganizationRole.Owner,
  OrganizationRole.Teacher
]);
export const hasPermissionUpdateJobTeacher = hasTutoringProgramWithRole([
  OrganizationRole.Owner,
  OrganizationRole.Teacher
]);
export const hasPermissionReopenJob = hasTutoringProgramWithRole([OrganizationRole.Owner]);

export const hasPermissionViewSchedules = hasTutoringProgramWithRole([
  OrganizationRole.Teacher,
  OrganizationRole.Member,
  OrganizationRole.Student
]);

export const hasPermissionViewTutors = createSelector([getOwnedAgencies], (organizations) => organizations.length > 0);
export const hasPermissionViewStudents = createSelector(
  [getOrganizationsWithManageRole],
  (organizations) => organizations.length > 0
);
export const hasPermissionInviteStudent = hasTutoringProgramWithRole([OrganizationRole.Teacher]);
export const hasPermissionRemoveStudent = hasTutoringProgramWithRole([OrganizationRole.Teacher]);

export const getAgreements = createSelector([get], (c) => c?.agreements ?? []);

export const shouldConsent = createSelector(
  [getAgreements],
  (agreements) => !agreements.some((type) => type === AgreementType.General)
);

export const getOrganizationWithRequiredSubdomain = createSelector([getOrganizations], (organizations) =>
  organizations.find((o) => !o.subdomain && o.subscription.attributes.subdomain)
);

export const getOwnedIndividualOrganization = createSelector([getOwnedOrganizations], (organizations) =>
  organizations.find((o) => o.type === OrganizationType.Individual)
);

export const getBrambleDirect = createSelector(
  [getOwnedIndividualOrganization],
  (organization) => organization?.brambleDirect
);

export const canAccessBrambleDirect = createSelector([getUser], () => features.isAvailable(Feature.BrambleDirect));
export const canActivateBrambleDirect = createSelector(
  [canAccessBrambleDirect, getOwnedIndividualOrganization],
  (canAccessBrambleDirect, organization) => canAccessBrambleDirect && !!organization
);
export const hasBrambleDirectActivated = createSelector([getBrambleDirect], (brambleDirect) => !!brambleDirect);

export const canCreateInvoice = createSelector(
  [hasBrambleDirectActivated],
  (hasBrambleDirectActivated) => !!hasBrambleDirectActivated
);
export const canViewInvoices = createSelector(
  [canAccessBrambleDirect, canActivateBrambleDirect, hasBrambleDirectActivated],
  (canAccessBrambleDirect, canActivateBrambleDirect, hasBrambleDirectActivated) =>
    !!canAccessBrambleDirect && (canActivateBrambleDirect ? hasBrambleDirectActivated : true)
);

export const canConsentToBrambleDirect = createSelector(
  [getAgreements],
  (agreements) => !agreements.some((type) => type === AgreementType.BrambleDirect)
);

export const shouldConsentToBrambleDirect = createSelector(
  [get, canConsentToBrambleDirect],
  (context, can) => !!context?.childIds?.length && can
);

export const canAccessSmartResources = createSelector([getUser], () => features.isAvailable(Feature.SmartResources));
