import { TutoringProgramDeliveryType } from './tutoring-program';

export enum JobFilterStatus {
  Open = 'created',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  Past = 'past'
}

export enum JobStatus {
  Created = 'created',
  Confirmed = 'confirmed',
  Canceled = 'canceled'
}

interface JobAttribute {
  id: number;
  name: string;
}

interface JobSchool extends JobAttribute {
  zip: string;
}

export interface JobCommon {
  id: number;
  subject: JobAttribute;
  subjectLevel: JobAttribute;
  sen: boolean;
  notes?: string;
  schedules: Date[];
  school: JobSchool;
  deliveryType: TutoringProgramDeliveryType;
  status: JobStatus;
  tutor?: JobParticipant;
  closesAt: string;
  lastScheduleAt: string;
  organization: { id: number; name: string };
}

export interface JobItem extends JobCommon {
  applied: boolean;
  students: number;
  applications: number;
}

export interface JobApplication {
  tutor: { id: number; name: string; image: string; experience: string; description?: string };
  notes?: string;
}

export interface JobParticipant extends JobAttribute {
  email: string;
}

export interface JobStudentParticipant extends Partial<JobParticipant> {
  profile?: { yearGroup?: number };
}

export interface JobDetail extends JobCommon {
  students: JobStudentParticipant[];
  applications: number | JobApplication[];
  application: Pick<JobApplication, 'notes'>;
  teachers?: JobParticipant[];
  unavailableSchedules?: Date[];
}

export interface CreateJob {
  organizationId: number;
  schoolId: number;
  teachers: number[];
  students: number[];
  subjectId: number;
  subjectLevelId: number;
  deliveryType: TutoringProgramDeliveryType;
  sen: boolean;
  notes?: string;
  schedules: Date[];
  code?: string;
}

export const JobFilters: Record<JobFilterStatus, (job: JobCommon) => boolean> = {
  [JobFilterStatus.Open]: (job) => job.status === JobStatus.Created,
  [JobFilterStatus.Confirmed]: (job) =>
    job.status === JobStatus.Confirmed && new Date(job.lastScheduleAt).getTime() >= new Date().getTime(),
  [JobFilterStatus.Past]: (job) =>
    job.status !== JobStatus.Created && new Date(job.lastScheduleAt).getTime() < new Date().getTime(),
  [JobFilterStatus.Canceled]: (job) => job.status === JobStatus.Canceled
};
