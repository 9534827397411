import classnames from 'classnames';
import * as React from 'react';

interface Props {
  htmlFor?: string;
  className?: string;
}

export const Label: React.FC<Props> = (props) => (
  <label {...props} className={classnames('text-sm text-gray-500', props.className)} />
);
