import * as React from 'react';

import { Button } from '@/component/UiKit/Button';
import * as Sentry from '@/service/sentry';

import style from './ErrorBoundary.sass';

export const ErrorBoundary: React.FC = ({ children }) => (
  <Sentry.ErrorBoundary
    showDialog={false}
    fallback={({ eventId, resetError }) => (
      <div className={style.root}>
        <div className={style.content}>
          <div className={style.message}>
            We&apos;re sorry but something has gone wrong. Our team has been notified about it.
            <br />
            Event Id: {eventId}
          </div>

          <Button appearance="secondary" onClick={resetError}>
            Try again
          </Button>
        </div>
      </div>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);
