import classnames from 'classnames';
import * as React from 'react';

import { ErrorBoundary } from '@/component/ErrorBoundary';

interface Props {
  className?: string;
}

export const Page: React.FC<Props> = (props) => (
  <ErrorBoundary>
    <main {...props} className={classnames('relative flex-1', props.className)} />
  </ErrorBoundary>
);
