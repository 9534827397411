import classnames from 'classnames';
import * as React from 'react';

import style from './Spacer.sass';

type Magnitude = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props {
  top?: Magnitude;
  bottom?: Magnitude;
  padding?: boolean;
  className?: string;
}

export const Spacer: React.FC<Props> = ({ top, bottom, padding, className, ...rest }) => (
  <div
    className={classnames(
      {
        // @ts-ignore
        [style[`top${top!}`]]: top,
        // @ts-ignore
        [style[`bottom${bottom!}`]]: bottom,
        [style.padding]: padding
      },
      className
    )}
    {...rest}
  />
);
