import { OrganizationType } from '@/constant';

import { SubscriptionStatus } from './subscription';

export enum OrganizationRole {
  Owner = 'OWNER',
  LeadTutor = 'LEADTUTOR',
  Member = 'MEMBER',
  Teacher = 'TEACHER',
  Student = 'STUDENT'
}

export interface OrganizationSubscription {
  status: SubscriptionStatus;
  expireAt: Date;
  valid: boolean;
  attributes: SubscriptionAttributes;
}

export enum OrganizationPermissions {
  None = 0x0000,
  AllowSessionRemovalForTutor = 0x0001,
  AllowSessionRemovalForStudent = 0x0002,
  AllowSessionFeedback = 0x0004
}

export interface OrganizationSettings {
  permissions: number;
  subdomain?: string;
}

export interface SubscriptionAttributes {
  rooms: number;
  tutors: number;
  subdomain: boolean;
}

export interface BrambleDirectTutorEnrollment {
  organizationId: number;
  currency: string;
}

export interface Organization {
  id: number;
  name: string;
  subdomain?: string;
  role: OrganizationRole;
  type: OrganizationType;
  subscription: OrganizationSubscription;
  valid: boolean;
  settings: OrganizationSettings;
  tutoringProgram: boolean;
  schoolIds: number[];
  brambleDirect?: BrambleDirectTutorEnrollment;
}
