import { Context } from '@/model';

import { ACTION_CONTEXT_UPDATE } from './action';

const updateContextReducer = (state: Context | null, context: Context | null): Context | null => {
  return context ? { ...context } : null;
};

export const initialState = null;

export default function contextReducer(state: Context | null = initialState, action: any): Context | null {
  switch (action.type) {
    case ACTION_CONTEXT_UPDATE:
      return updateContextReducer(state, action.payload);
  }

  return state;
}
