import { AnyAction, combineReducers } from 'redux';

import contextReducer, { initialState as contextInitialState } from './context';
import sessionReducer, { initialState as sessionInitialState } from './session';
import { ACTION_SESSION_SIGN_OUT } from './session/action';
import { State } from './state';

export const initialState = {
  session: sessionInitialState,
  context: contextInitialState
};

const appReducer = combineReducers({
  session: sessionReducer,
  context: contextReducer
});

export default function rootReducer(state: State, action: AnyAction) {
  state = appReducer(state, action);

  if (action.type === ACTION_SESSION_SIGN_OUT) {
    state = {
      ...initialState
    };
  }

  return state;
}
