import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};
