import * as React from 'react';

type Callback<T> = () => Promise<T>;
type Execute = () => Promise<any>;
interface State<T> {
  loading: boolean;
  value: T | undefined;
  error: string | undefined;
}

export const useAsync = <T>(callback: Callback<T>, immediate = true): [State<T>, Execute] => {
  const [loading, setLoading] = React.useState<State<T>['loading']>(immediate);
  const [value, setValue] = React.useState<State<T>['value']>(undefined);
  const [error, setError] = React.useState<State<T>['error']>(undefined);

  const execute = React.useCallback(() => {
    setLoading(true);

    return callback()
      .then((response) => setValue(response))
      .catch((error) => {
        error = error
          ? error.response && error.response.data
            ? error.response.data.message
            : error.message
          : error.message;

        setError(error);

        throw error;
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [callback]);

  React.useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return React.useMemo(() => [{ loading, value, error }, execute], [loading, value, error, execute]);
};
