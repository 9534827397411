import { AgreementType, Context } from '@/model';
import { createAction } from '@/redux/action';
import * as session from '@/redux/session';
import { contextService } from '@/service/business/context';
import { passwordService } from '@/service/business/password';
import { profileService } from '@/service/business/profile';
import { setupService } from '@/service/business/setup';
import { features } from '@/service/features';
import * as Sentry from '@/service/sentry';

const createContextAction = (name: string) => createAction(`CONTEXT_${name}`);

export const ACTION_CONTEXT_UPDATE = createContextAction(`UPDATE`);

export const updateContext = (context: Context) => {
  Sentry.setUser({
    id: `${context.user.id}`,
    email: context.user.email,
    username: context.user.email
  });

  features.configure(context.user.features);

  return {
    type: ACTION_CONTEXT_UPDATE,
    payload: context
  };
};

export const load = () => {
  return (dispatch: Function) => {
    return contextService.get().then((context) => dispatch(updateContext(context)));
  };
};

export const validateSetupToken = (token: string) => {
  return () => setupService.validate(token);
};

export interface SetupMeParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  birthdate: Date;
  token: string;
}

export const setupMe = ({ email, password, firstName, lastName, birthdate, token }: SetupMeParams) => {
  return (dispatch: Function) => {
    return setupService
      .setup({ password, firstName, lastName, birthdate, token })
      .then(() => dispatch(session.signInWithEmail(email, password)))
      .then(() => dispatch(load()));
  };
};

export const updateMe = (params: { firstName?: string; lastName?: string; birthdate?: string }) => {
  return (dispatch: Function) => {
    return profileService.update(params).then(() => dispatch(load()));
  };
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  return (dispatch: Function) => {
    return passwordService.changePassword(oldPassword, newPassword).then(() => dispatch(load()));
  };
};

export const acceptAgreement = (type: AgreementType) => {
  return (dispatch: Function) => setupService.acceptAgreement(type).then(() => dispatch(load()));
};
