import 'babel-polyfill';

import { parse } from 'query-string';
import * as React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'reset-css/reset.css';
import 'tailwindcss/tailwind.css';

import '@/service/sentry';

import { SessionToken } from '@/model';
import * as session from '@/redux/session';
import { configureStore } from '@/redux/store';
import { storageService } from '@/service/storage';

import { PageViewTracker } from '@/component/Analytics/PageViewTracker';
import { ConfigurationProvider } from '@/component/Context/Configuration';
import { ErrorBoundary } from '@/component/ErrorBoundary';

import { App } from './App';

const renderCallback = process.env.NODE_ENV === 'production' ? hydrate : render;

const dom: HTMLElement | null = document.getElementById('app');

const store = configureStore();

const query = parse(window.location.search);

const getSingle = (value: string | string[]) => (Array.isArray(value) ? value[0] : value);

const sessionToken: SessionToken = query.accessToken
  ? { accessToken: getSingle(query.accessToken), refreshToken: 'REDACTED' }
  : storageService.getItem('session');

store.dispatch(session.updateSession(sessionToken));

renderCallback(
  <ErrorBoundary>
    <BrowserRouter>
      <React.Fragment>
        <ConfigurationProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </ConfigurationProvider>

        <PageViewTracker />
      </React.Fragment>
    </BrowserRouter>
  </ErrorBoundary>,
  dom
);
