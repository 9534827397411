import classnames from 'classnames';
import * as React from 'react';

interface Props {
  stretch?: boolean;
  padding?: boolean;
  className?: string;
}

export const PageContent: React.FC<Props> = ({ stretch, padding = true, className, ...rest }) => (
  <div
    {...rest}
    className={classnames('relative mx-auto px-5 md:px-[80px]', { 'max-w-7xl': !stretch, 'pb-10': padding }, className)}
  />
);
