import { AnyAction, applyMiddleware, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import rootReducer, { initialState } from './reducer';
import { apiAuthMiddleware } from './session';
import { State } from './state';

const middlewares = [
  thunk as ThunkMiddleware<State, AnyAction>,
  apiAuthMiddleware as ThunkMiddleware<State, AnyAction>
];

const createStoreWithMiddlewares = applyMiddleware(...middlewares)(createStore);

export const configureStore = (state: State = initialState) =>
  createStoreWithMiddlewares(rootReducer as any, state as any);
