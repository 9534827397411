import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { urls } from '@/constant';

import { ConsumeRedirectRoute } from '@/component/Navigation/ConsumeRedirectRoute';
import { RedirectWithPersist } from '@/component/Navigation/RedirectWithPersist';

import { ForgotPasswordRequestPage } from './ForgotPasswordRequestPage';
import { ForgotPasswordResetPage } from './ForgotPasswordResetPage';

export const ForgotPasswordPage: React.FC = () => (
  <Switch>
    <Route exact path={urls.forgotPassword.send} component={ForgotPasswordRequestPage} />
    <Route exact path={urls.forgotPassword.reset} component={ForgotPasswordResetPage} />
    <ConsumeRedirectRoute render={() => <RedirectWithPersist to={urls.forgotPassword.send} />} />
  </Switch>
);
