import classnames from 'classnames';
import React from 'react';

import { Input, Props as InputProps } from '../Input';

interface Props extends Omit<InputProps<'input'>, 'is'> {
  contentClassName?: string;
}

export const Checkbox = React.forwardRef(
  (
    { invalid, className, contentClassName, children, ...rest }: React.PropsWithChildren<Props>,
    ref: React.Ref<any>
  ) => (
    <label className={classnames('flex cursor-pointer select-none', className)}>
      <Input
        {...{ ref: ref as any, invalid }}
        {...rest}
        className="mt-0.5 h-4 w-4 cursor-pointer rounded text-gray-900"
      />

      {!!children && <div className={classnames('ml-2', contentClassName)}>{children}</div>}
    </label>
  )
);
