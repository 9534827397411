import { IsComponent } from '@/component/Is';
import moment from 'moment';
import * as React from 'react';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';

import '@/util/yup-date';

import { getValueFromEvent, MaskedInput, Props as MaskInputProps } from '../MaskedInput';

type PotentialDate = string | Date | null | undefined;

export type Props<T extends IsComponent> = MaskInputProps<T> & {
  value?: PotentialDate;
  mask: string;
  onChange(value: PotentialDate): void;
};

// text-mask is using dd/mm/yyyy HH:MM while moment is using DD/MM/YYYY HH:mm
const flipCasing = (mask: string) => {
  return mask.replace(/[a-zA-Z]/g, (letter) => {
    if (letter === 'H') return letter;

    const lowercased = letter.toLowerCase();

    if (lowercased !== letter) return lowercased;

    return letter.toUpperCase();
  });
};

export const MASK_DATE = 'DD / MM / YYYY';
export const MASK_TIME = 'HH:mm';

export const DateInput = React.forwardRef(function DateInput<T extends IsComponent>(
  { mask: rawMask, value: rawValue, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  const { mask, pipe } = React.useMemo(() => {
    const pipe = createAutoCorrectedDatePipe(flipCasing(rawMask));
    const mask = rawMask.split('').map((letter) => (/[a-zA-Z]/.test(letter) ? /\d/ : letter));

    return { pipe, mask };
  }, [rawMask]);

  const value = React.useMemo(() => {
    return rawValue instanceof Date ? moment(rawValue).format(rawMask) : rawValue;
  }, [rawValue, rawMask]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = getValueFromEvent(event);
      const date = moment(value, rawMask, true);

      return props.onChange(date.isValid() ? moment(date.format(rawMask), rawMask, true).toDate() : value);
    },
    [props.onChange, rawMask]
  );

  return (
    <MaskedInput {...props} {...{ ref, value, mask, pipe, onChange }} guide keepCharPositions placeholder={rawMask} />
  );
});
