export enum SessionPermission {
  ViewPlayback = 0x0001,
  ViewDetail = 0x0002,
  UpdateSubject = 0x0010,
  Delete = 0x1000
}

export interface Conference {
  id: number;
  start: Date;
  end: Date;
  duration: number;
  playbackUrl?: string;
  imageUrl: {
    small: string;
    large: string;
  };
  notebookUrls: string[];
  participants: {
    email: string;
    name?: string;
    devices: string[];
    start: Date;
    end: Date;
    statistics: {
      words: 0;
    };
  }[];
  room: string;
  subjects: SessionSubject[];
  feedback: [boolean, boolean];
  organizationId?: number;
  permissions: number;
}

export interface SessionDetail {
  session: {
    id: number;
    start: Date;
    end: Date;
    duration: number;
    room: string;
    playbackUrl: string;
    imageUrl: {
      small: string;
      large: string;
    };
    notebookUrls: string[];
    participants: {
      id: number;
      email: string;
      name?: string;
      role: string;
      start: Date;
      end: Date;
      duration: number;
      words: number;
      strokes: number;
      texts: number;
      resources: number;
    }[];
    subjects: SessionSubject[];
    organizationId: number;
  };
  feedback?: SessionFeedback;
}

export enum SubjectType {
  Manual = 'manual',
  Automatic = 'automatic'
}

export interface SessionSubject {
  sessionId: number;
  subjectId: number;
  userId?: number;
  type: SubjectType;
  createdAt: string;
}

export enum AssessmentType {
  PreTest = 'pre-test',
  MidTest = 'mid-test',
  PostTest = 'post-test',
  Other = 'other'
}

export interface SessionAssessment {
  id?: number;
  studentId: number;
  subjectId?: number;
  type: AssessmentType;
  score?: number;
}

export interface SessionRating {
  studentId: number;
  engagement?: number;
  understanding?: number;
  confidence?: number;
  comments?: string;
  createdByParticipantId?: number;
}

export interface SessionFeedback {
  feedback?: {
    comments?: string;
  };
  ratings: SessionRating[];
  assessments: SessionAssessment[];
}

export const assessmentTypeName: Record<AssessmentType, string> = {
  [AssessmentType.PreTest]: 'Pre Test',
  [AssessmentType.MidTest]: 'Mid Test',
  [AssessmentType.PostTest]: 'Post Test',
  [AssessmentType.Other]: 'Other'
};
