export enum TutoringProgramDeliveryType {
  OnlineSchool = 0x01,
  OnlineHome = 0x02,
  Offline = 0x04
}

export enum TutoringProgramPurchaseFlow {
  BookingCode = 'booking-code'
}

export interface TutoringProgram {
  id: number;
  organizationId: number;
  tutoringProgramId: number;
  deliveryType: TutoringProgramDeliveryType;
  purchaseFlow: TutoringProgramPurchaseFlow;
  minSessions: number;
  maxSessions: number;
  minStudents: number;
  maxStudents: number;
  enabled: boolean;
}
