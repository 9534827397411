import { Context } from '@/model';
import { authorizedApi } from '@/service/network/authorized-api';

export class ContextService {
  get = () => {
    return authorizedApi.get<Context>(`/context`);
  };
}

export const contextService = new ContextService();
