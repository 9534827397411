import classnames from 'classnames';
import * as React from 'react';

import { Header } from '@/component/Header';
import { Page } from '@/component/Page';

interface Props extends React.ComponentProps<typeof Page> {
  title: string;
  header?: React.ReactNode;
  contentClassName?: string;
}

export const IdentityPage: React.FC<Props> = ({ title, header, className, contentClassName, children }) => (
  <Page className={classnames('flex flex-col', className)}>
    <Header title={title} withLogo>
      {header}
    </Header>

    <div className="flex flex-1 items-center justify-center py-6">
      <div className={classnames('w-full max-w-lg rounded-lg bg-white p-8 shadow-lg', contentClassName)}>
        {children}
      </div>
    </div>
  </Page>
);
