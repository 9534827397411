import { api } from '@/service/network/api';

import { SessionToken } from '@/model/session';

export class SessionService {
  signInWithEmail = (email: string, password: string) => {
    return api.post<SessionToken>(`/auth/email/sign-in`, { email, password }, { sentry: { status: [401] } });
  };

  signInWithGoogle = (idToken: string) => {
    return api.post<SessionToken>(`/auth/google/sign-in`, { idToken });
  };

  signUpWithEmail = (email: string, organization?: { id: number; role: string }) => {
    return api.post<null>(`/auth/email/sign-up`, { email, organization });
  };

  renewToken = (token: string) => {
    return api.post<SessionToken>(`/auth/renew`, { token }, { sentry: { status: [401] } });
  };

  signOut = (token: string) => {
    return api.post<null>(`/auth/sign-out`, { token });
  };
}

export const sessionService = new SessionService();
