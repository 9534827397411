import classnames from 'classnames';
import React from 'react';

import { IsComponent } from '@/component/Is';
import { Input, Props as InputProps } from '@/component/UiKit/Input';

export type { InputProps as Props };

export const TextInput = React.forwardRef(function TextInput<T extends IsComponent>(
  props: React.PropsWithChildren<InputProps<T>>,
  ref: React.Ref<any>
) {
  return (
    // @ts-ignore
    <Input
      {...props}
      {...{ ref }}
      className={classnames(
        'box-border block h-10 w-full rounded text-sm',
        { 'bg-gray-100': props.disabled || props.readOnly },
        props.className
      )}
    />
  );
});
