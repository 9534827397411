import { SessionToken } from '@/model/session';

import { ACTION_SESSION_UPDATE } from './action';

const updateSessionReducer = (state: SessionToken | null, session: SessionToken | null): SessionToken | null => {
  return session
    ? {
        ...session
      }
    : null;
};

export const initialState = null;

export default (state: SessionToken | null = initialState, action: any): SessionToken | null => {
  switch (action.type) {
    case ACTION_SESSION_UPDATE:
      return updateSessionReducer(state, action.payload);
  }

  return state;
};
