import { AgreementType, Context } from '@/model';
import { api } from '@/service/network/api';
import { authorizedApi } from '@/service/network/authorized-api';
import { dateToUTC } from '@/util/date';

interface ValidateResponse {
  email: string;
  organization?: { id: number; role: string };
}

interface SetupParams {
  firstName: string;
  lastName: string;
  password: string;
  birthdate: Date;
  token: string;
}

export class SetupService {
  validate = (token: string) => {
    return api.post<ValidateResponse>(`/setup/validate`, { token }, { sentry: { status: [409] } });
  };

  setup = (data: SetupParams) => {
    return api.post<Context>(`/setup`, { ...data, birthdate: dateToUTC(data.birthdate) });
  };

  enrollAsStudent = () => {
    return authorizedApi.post<null>(`/setup/enroll/student`).then(() => null);
  };

  acceptAgreement = async (agreement: AgreementType) => {
    await authorizedApi.post<null>(`/setup/agreements/accept`, { agreement });
  };
}

export const setupService = new SetupService();
