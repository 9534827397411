import React from 'react';
import { useField } from 'react-final-form';

import { Is, IsComponent, Props as IsProps } from '@/component/Is';
import { ValidationMessage } from '@/component/UiKit/ValidationMessage';

const usePropagatedHandler = <T extends React.FocusEvent<HTMLElement>, R>(
  fieldCallback: (event: T) => R,
  propCallback?: (event: T) => R
) => {
  const callback = React.useCallback(
    (event: T) => {
      fieldCallback(event);

      return propCallback?.(event);
    },
    [fieldCallback, propCallback]
  );

  return callback;
};

type Props<T extends IsComponent> = Omit<IsProps<T>, 'onChange'> & {
  validated?: boolean;
  parse?(value: any, name: string): any;
  format?(value: any, name: string): any;
};

export function FormField<T extends IsComponent>({
  name,
  type,
  validated,
  parse,
  format,
  ...props
}: React.PropsWithChildren<Props<T>>) {
  const { meta, input } = useField(name, { type, parse, format });

  const error = React.useMemo(() => {
    if (!validated) return undefined;

    const invalid = meta.submitError ? !!meta.submitError && !meta.dirtySinceLastSubmit : meta.invalid;

    if (!invalid) return undefined;

    const visible = !!meta.touched && invalid; // !meta.active &&
    const message = meta.error?.id || meta.submitError;

    return { visible, message };
  }, [validated, meta]);

  const onFocus = usePropagatedHandler(input.onFocus, props.onFocus);
  const onBlur = usePropagatedHandler(input.onBlur, props.onBlur);
  const onChange = usePropagatedHandler(input.onChange, props.onChange);

  return (
    <React.Fragment>
      {/**
      @ts-ignore */}
      <Is {...{ type }} {...props} {...input} invalid={!!error?.visible} {...{ onFocus, onBlur, onChange }} />
      {!!validated && <ValidationMessage visible={!!error?.visible}>{error?.message}</ValidationMessage>}
    </React.Fragment>
  );
}
