declare const CONFIG: {
  API: string;
  STRIPE_PUBLIC_KEY: string;
  GA_TRACKING_ID?: string;
  ENVIRONMENT: string;
  SENTRY_DSN?: string;
  FEATURES: string;
  VERSION: string;
  VERSION_URL: string;
  ASSET_PREFIX: string;
  GOOGLE_CLIENT_ID: string;
};

export const {
  API,
  STRIPE_PUBLIC_KEY,
  FEATURES,
  GOOGLE_CLIENT_ID,
  GA_TRACKING_ID,
  ENVIRONMENT,
  SENTRY_DSN,
  VERSION,
  VERSION_URL,
  ASSET_PREFIX
} = CONFIG;
