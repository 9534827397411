import classnames from 'classnames';
import * as React from 'react';

interface Props {
  is?: any;
  className?: string;
  [x: string]: any;
}

export const Link: React.FC<Props> = ({ is = 'a', className, ...rest }) => {
  const Component: any = is;

  return (
    <Component
      className={classnames(
        'cursor-pointer text-gray-600 underline hover:text-gray-700 focus:text-gray-700',
        className
      )}
      {...rest}
    />
  );
};
