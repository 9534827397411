import { createSelector } from 'reselect';

import { SessionToken } from '@/model/session';
import { State } from '@/redux/state';

export const getSession = (state: State): SessionToken | null => state.session;

export const getAccessToken = createSelector([getSession], (session) => (session ? session.accessToken : null));

export const getRefreshToken = createSelector([getSession], (session) => (session ? session.refreshToken : null));

export const isAuthenticated = createSelector([getRefreshToken], (token) => !!token);
