import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { useAsync } from '@/component/hooks/use-async';
import { useQuery } from '@/component/hooks/use-query';
import { urls } from '@/constant';
import * as context from '@/redux/context';

import { ButtonContainer } from '@/component/ButtonContainer';
import { Form, FormField, handleError } from '@/component/Form';
import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { getRedirectUrlFromParams, replaceQueryParams } from '@/component/Navigation/redirect';
import { Button } from '@/component/UiKit/Button';
import { Checkbox } from '@/component/UiKit/Checkbox';
import { DateInput, MASK_DATE } from '@/component/UiKit/DateInput';
import { Label } from '@/component/UiKit/Field/Label';
import { Link, LinkWithPersist } from '@/component/UiKit/Link';
import { Loading, LoadingIndicator } from '@/component/UiKit/Loading';
import { TextInput } from '@/component/UiKit/TextInput';
import { ValidationMessage } from '@/component/UiKit/ValidationMessage';

const schema = yup
  .object({
    organization: yup
      .object({ id: yup.number().required(), role: yup.string().required() })
      .notRequired()
      .default(undefined),
    email: yup.string().required(),
    password: yup.string().min(8).required(),
    firstName: yup.string().label('first name').required(),
    lastName: yup.string().label('last name').required(),
    birthdate: yup.date().label('date of birth').required(),
    accepted: yup
      .boolean()
      .isTrue('Please agree to the Bramble privacy policy to continue.')
      .required('Please agree to the Bramble privacy policy to continue.')
      .label('agreement')
  })
  .required();

export const SignUpSetupPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { token } = useQuery<{ token: string }>();
  const redirect = getRedirectUrlFromParams(location.pathname, location.search);

  const dispatch = useDispatch();
  const hasTutoringProgram = useSelector(context.hasTutoringProgram);
  const isTeacher = useSelector(context.isTeacher);

  const load = React.useCallback(() => dispatch(context.validateSetupToken(token!)), [token]);

  const [{ loading, value: data = {}, error }] = useAsync(load, !!token);

  const submit = React.useCallback(
    (values: yup.InferType<typeof schema>) => {
      return dispatch(context.setupMe({ token: token!, ...values }))
        .then(() => history.push(replaceQueryParams(urls.signUp.redirect, { redirect })))
        .then(() => null)
        .catch(
          handleError({
            400: (error) => error.fields || {},
            409: () => `You account has been setup already. Sign in instead.`,
            default: () => `Unable to set up your account. The link might be expired.`
          })
        );
    },
    [hasTutoringProgram, isTeacher, data, redirect]
  );

  if (!token) return <Redirect to={urls.signUp.send} />;

  return (
    <IdentityPage
      title="Sign up"
      header={
        <Button is={LinkWithPersist} appearance="primary" to={urls.signIn}>
          Log In
        </Button>
      }
      contentClassName="relative space-y-6"
    >
      <Loading visible={loading} className="absolute inset-0 bg-gray-50">
        <LoadingIndicator className="absolute top-1/2 left-1/2 h-8 w-8 -translate-x-1/2 -translate-y-1/2 text-gray-900" />
      </Loading>

      <Form schema={schema} initialValues={data} onSubmit={submit}>
        {({ submitError, submitting, handleSubmit }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <IdentityTitle title="Setup">Set up your account</IdentityTitle>

            <div className="space-y-1">
              <Label htmlFor="email">Email address</Label>

              <FormField
                is={TextInput}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="nick@email.com"
                readOnly
                validated
                className="bg-gray-100"
              />
            </div>

            <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
              <div className="space-y-1">
                <Label htmlFor="first-name">First name</Label>

                <FormField
                  is={TextInput}
                  id="first-name"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  placeholder="Charles"
                  readOnly={submitting || !!error}
                  validated
                />
              </div>

              <div className="space-y-1">
                <Label htmlFor="last-name">Last name</Label>

                <FormField
                  is={TextInput}
                  id="last-name"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  placeholder="Darwin"
                  readOnly={submitting || !!error}
                  validated
                />
              </div>
            </div>

            <div className="space-y-1">
              <Label htmlFor="birthdate">Date of birth</Label>

              <FormField
                is={DateInput}
                id="birthdate"
                name="birthdate"
                type="text"
                readOnly={submitting || !!error}
                validated
                mask={MASK_DATE}
              />
            </div>

            <div className="space-y-1">
              <Label htmlFor="password">Password</Label>

              <FormField
                is={TextInput}
                id="password"
                name="password"
                type="password"
                placeholder="your secure password"
                readOnly={submitting || !!error}
                validated
              />
            </div>

            <FormField
              is={Checkbox}
              id="accepted"
              name="accepted"
              type="checkbox"
              readOnly={submitting}
              className="text-sm"
              validated
            >
              I confirm that I am 13 or over, or an adult with parental responsibility for a user under 13, and agree to
              the Bramble{' '}
              <Link href={urls.legal.privacyPolicy} target="_blank" rel="noopener noreferrer">
                privacy policy
              </Link>
              .
            </FormField>

            <ValidationMessage visible={!!submitError || !!error}>{submitError || error}</ValidationMessage>

            <ButtonContainer>
              <Button appearance="primary" type="submit" loading={submitting} disabled={!!error}>
                Set up account
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Form>

      <div className="space-y-2 text-sm">
        <div>
          By setting up an account you agree to{' '}
          <Link href={urls.legal.termsAndConditions} target="_blank" rel="noopener noreferrer">
            our terms
          </Link>
          .
        </div>

        <div>
          Already have your account setup?{' '}
          <Link is={LinkWithPersist} to={urls.signIn}>
            Log in
          </Link>
        </div>
      </div>
    </IdentityPage>
  );
};
