import classnames from 'classnames';
import * as React from 'react';

import { Title } from '@/component/Title';

interface Props extends React.ComponentProps<typeof Title> {}

export const PageTitle: React.FC<Props> = (props) => (
  <Title {...props} className={classnames('text-2xl font-semibold text-gray-900', props.className)} />
);
