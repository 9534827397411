import { api } from '@/service/network/api';
import { authorizedApi } from '@/service/network/authorized-api';

import { OrganizationType } from '@/constant';
import { Organization, OrganizationSettings, School, TutoringProgram } from '@/model';
import { Configuration } from '@/model/configuration';

export class OrganizationService {
  createIndividual = (subdomain: string | undefined, id: string) => {
    return authorizedApi.post<Organization>(`/organizations`, {
      organization: {
        name: 'Personal',
        subdomain,
        type: OrganizationType.Individual
      },
      purchase: {
        plan: { id, quantity: 1 }
      }
    });
  };

  createAgency = (name: string, subdomain: string, id: string, quantity: number) => {
    return authorizedApi.post<Organization>(`/organizations`, {
      organization: {
        name,
        subdomain,
        type: OrganizationType.Agency
      },
      purchase: {
        plan: { id, quantity }
      }
    });
  };

  invite = (id: number, email: string) => {
    return authorizedApi.post<null>(`/organizations/${id}/invite`, {
      email
    });
  };

  updateSettings = (id: number, settings: OrganizationSettings) => {
    return authorizedApi.post<null>(`/organizations/${id}/settings`, settings);
  };

  getTutoringProgram = (id: number) => {
    return authorizedApi.get<TutoringProgram>(`/organizations/${id}/tutoring-program`);
  };

  getSchools = (id: number) => {
    return authorizedApi.get<School[]>(`/organizations/${id}/schools`);
  };

  getConfiguration = (id: number) => {
    return api.get<Configuration>(`/organizations/${id}/configuration`);
  };

  validateSubdomain = (subdomain: string) => {
    return authorizedApi.get<{ valid: boolean }>(`/organizations/subdomains/validate`, { subdomain });
  };
}

export const organizationService = new OrganizationService();
