import * as React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';

import { urls } from '@/constant';
import { passwordService } from '@/service/business/password';

import { ButtonContainer } from '@/component/ButtonContainer';
import { Form, FormField, handleError } from '@/component/Form';
import { useQuery } from '@/component/hooks/use-query';
import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { Button } from '@/component/UiKit/Button';
import { Label } from '@/component/UiKit/Field/Label';
import { Link, LinkWithPersist } from '@/component/UiKit/Link';
import { TextInput } from '@/component/UiKit/TextInput';
import { ValidationMessage } from '@/component/UiKit/ValidationMessage';

const schema = yup
  .object({
    password: yup.string().min(8).required(),
    confirmPassword: yup
      .string()
      .equalTo(yup.ref('password'), 'Should be the same as the password.')
      .required()
      .label('password confirmation')
  })
  .required();

export const ForgotPasswordResetPage: React.FC = () => {
  const { token } = useQuery<{ token?: string }>();
  const { params } = useRouteMatch<{ userId: string }>();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (!token) return `The link is invalid or has expired.`;

      return passwordService
        .resetPasswordWithToken(+params.userId, values.password, token)
        .then(() => null)
        .catch(
          handleError({
            [400]: (error) => error.fields ?? error.message,
            default: () => `Couldn't reset your password for some reason. Please try again later.`
          })
        );
    },
    [params, token]
  );

  return (
    <IdentityPage
      title="Reset password"
      header={
        <Button is={RouterLink} appearance="primary" to={urls.signIn}>
          Log In
        </Button>
      }
    >
      <Form {...{ schema, onSubmit }}>
        {({ submitError, submitSucceeded, submitting, handleSubmit }) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <IdentityTitle title="Reset your password">Reset your password</IdentityTitle>

            {submitSucceeded ? (
              <React.Fragment>
                <div className="text-sm">
                  Password reset successfully.
                  <br />
                  Use your password to log into your Bramble account.
                </div>

                <Button is={RouterLink} to={urls.signIn} appearance="primary" className="inline-flex">
                  Continue
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="text-sm">Reset your password associated with your email. The longer the better.</div>

                <div className="space-y-4">
                  <div className="space-y-1">
                    <Label htmlFor="password">Password</Label>

                    <FormField
                      is={TextInput}
                      id="password"
                      type="password"
                      name="password"
                      placeholder="your secure password"
                      readOnly={submitting}
                      validated
                    />
                  </div>

                  <div className="space-y-1">
                    <Label htmlFor="confirm-password">Confirm password</Label>

                    <FormField
                      is={TextInput}
                      id="confirm-password"
                      type="password"
                      name="confirmPassword"
                      placeholder="the same strong password"
                      readOnly={submitting}
                      validated
                    />
                  </div>

                  <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

                  <ButtonContainer>
                    <Button appearance="primary" type="submit" loading={submitting}>
                      Reset password
                    </Button>
                  </ButtonContainer>
                </div>

                <div className="text-sm">
                  Remember your password?{' '}
                  <Link is={LinkWithPersist} to={urls.signIn}>
                    Log in
                  </Link>
                </div>
              </React.Fragment>
            )}
          </form>
        )}
      </Form>
    </IdentityPage>
  );
};
