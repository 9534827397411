import memoizee from 'memoizee';

import { ip } from './ip';

class Timezone {
  private getFromBrowser() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {
      return undefined;
    }
  }

  private async getFromService() {
    const result = await ip.get();

    return result ? result.timezone : undefined;
  }

  get = memoizee(
    async () => {
      return this.getFromBrowser() || (await this.getFromService()) || 'Europe/London';
    },
    { maxAge: 1000 * 60 * 60 * 1, promise: true }
  );
}

export const tz = new Timezone();
