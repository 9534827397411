import classnames from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { PageTitle } from '@/component/Page/PageTitle';

interface Props extends React.ComponentProps<typeof PageTitle> {
  title: string;
}

export const IdentityTitle: React.FC<Props> = ({ title, className, ...rest }) => (
  <React.Fragment>
    <Helmet title={title} />
    <PageTitle {...rest} className={classnames('text-3xl font-semibold text-gray-900', className)} />
  </React.Fragment>
);
