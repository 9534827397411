import moment from 'moment';
import * as yup from 'yup';

import { errors } from '@/component/Form/errors';

function dateFormat(this: yup.DateSchema, format: string, message?: string) {
  const meta = this.meta();
  const limitFormat = (meta?.limitFormat as string) ?? format;

  const { tests } = this.describe();

  // Format min/max params
  tests
    .filter((t) => t.name && ['min', 'max'].includes(t.name))
    .forEach((t) => {
      if (!t.params || !t.name || !t.params[t.name]) return;
      t.params[t.name] = moment(t.params[t.name] as Date).format(limitFormat);
    });

  return this.test({
    name: 'format',
    exclusive: false,
    message: message ?? errors.mixed.default,
    params: { format },
    test: (value) => !value || moment(value, format, true).isValid()
  })
    .transform((_, originalValue) => {
      if (!originalValue) return originalValue;
      const date = moment(originalValue, format, true);
      return date.isValid() ? date.toDate() : originalValue;
    })
    .typeError(errors.mixed.default);
}

yup.addMethod<yup.DateSchema>(yup.date, 'format', dateFormat);
