import * as React from 'react';

import { ConfigurationContext } from '@/component/Context/Configuration';
import { Brand } from '@/model';

import logoBramble from './image/logo-bramble.png';
import logoPearson from './image/logo-pearson.svg';

const logos: Record<Brand, string> = {
  [Brand.Bramble]: logoBramble,
  [Brand.Pearson]: logoPearson
};

interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = (props) => {
  const { configuration } = React.useContext(ConfigurationContext);

  return <img src={logos[configuration.brand] || logos.bramble} alt="Bramble" role="presentation" {...props} />;
};
