import memoizee from 'memoizee';

import { HttpClient } from './network/http';

interface FreeGeoIPData {
  ip: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip_code: string;
  time_zone: string;
  latitude: number;
  longitude: number;
}

class IPService {
  private client = new HttpClient({ baseURL: `https://freegeoip.live` });

  get = memoizee(
    async () => {
      return this.client
        .get<FreeGeoIPData>(`/json/`)
        .then((result) => ({ timezone: result.time_zone }))
        .catch(() => undefined);
    },
    { maxAge: 1000 * 60 * 60 * 1, promise: true }
  );
}

export const ip = new IPService();
