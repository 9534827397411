import { SessionToken } from '@/model/session';
import { createAction } from '@/redux/action';

import { sessionService } from '@/service/business/session';
import { features } from '@/service/features';

import { getRefreshToken } from './selector';

const createSessionAction = (name: string) => createAction(`SESSION_${name}`);

export const ACTION_SESSION_UPDATE = createSessionAction(`UPDATE`);
export const ACTION_SESSION_SIGN_OUT = createSessionAction(`SIGN_OUT`);

export const updateSession = (session: SessionToken | null) => ({
  type: ACTION_SESSION_UPDATE,
  payload: session
});

export const signInWithEmail = (email: string, password: string) => {
  return (dispatch: Function) => {
    return sessionService.signInWithEmail(email, password).then((session) => dispatch(updateSession(session)));
  };
};

export const signInWithGoogle = (idToken: string) => {
  return (dispatch: Function) => {
    return sessionService.signInWithGoogle(idToken).then((session) => dispatch(updateSession(session)));
  };
};

export const signUpWithEmail = (email: string, organization?: { id: number; role: string }) => {
  return () => sessionService.signUpWithEmail(email, organization);
};

export const renewAccessToken = () => {
  return (dispatch: Function, getState: Function) => {
    const refreshToken = getRefreshToken(getState());

    const promise = refreshToken
      ? sessionService.renewToken(refreshToken)
      : Promise.reject(new Error(`Invalid session.`));

    return promise.then((session) => dispatch(updateSession(session)));
  };
};

export const signOut = () => {
  return (dispatch: Function, getState: Function) => {
    const refreshToken = getRefreshToken(getState());

    const promise: Promise<any> = refreshToken ? sessionService.signOut(refreshToken) : Promise.resolve();

    return promise
      .catch(() => null)
      .then(() => dispatch(updateSession(null)))
      .then(() => dispatch({ type: ACTION_SESSION_SIGN_OUT }))
      .then(() => features.configure(null));
  };
};
