import { AgreementType } from './agreement';
import { Configuration } from './configuration';
import { Organization } from './organization';

export enum UserType {
  Unknown = 'UNKNOWN',
  Student = 'STUDENT',
  Tutor = 'TUTOR',
  Teacher = 'TEACHER',
  Agency = 'AGENCY'
}

export interface User {
  id: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  name: string | null;
  hash: string;
  birthdate: string | null;
  features: string | null;
  state: number;
  type: UserType;
  verifiedAt?: Date;
}

export interface Context {
  user: User;
  organizations: Organization[];
  configuration: Configuration;
  agreements: AgreementType[];
  childIds: number[];
}
