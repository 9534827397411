import { FEATURES } from '@/config';

export enum Feature {
  Course = 'COURSE',
  AccountLink = 'ACCOUNT_LINK',
  BrambleDirect = 'BRAMBLE_DIRECT',
  SessionInsights = 'SESSION_INSIGHTS',
  SmartResources = 'SMART_RESOURCES'
}

type FeatureMap = { [f in Feature]?: boolean };

class FeatureService {
  Feature = Feature;

  private map: FeatureMap;

  constructor() {
    this.map = this.configure(null);
  }

  configure(features: string | null) {
    const splitFeatures = [FEATURES, features]
      .filter((feature) => feature)
      .join(',')
      .split(',');

    this.map = Object.values(Feature).reduce<FeatureMap>(
      (map, feature) => ({ ...map, [feature]: splitFeatures.indexOf(feature) >= 0 }),
      {}
    );

    return this.map;
  }

  isAvailable = (feature: Feature) => !!this.map[feature];
}

export const features = new FeatureService();
