import { generatePath } from 'react-router';

import { AgreementType } from '@/model';
import { OrganizationType } from './organization-type';

interface RouteConfig {
  [x: string]: string | RouteConfig | Function;
}

const createRoutes = <C extends RouteConfig>(base: string, config: C): C => {
  return Object.entries(config).reduce<C>((config, [key, value]) => {
    switch (typeof value) {
      case 'string': {
        // @ts-ignore
        config[key] = `${base}${value}`;
        break;
      }
      case 'function': {
        // @ts-ignore
        config[key] = value;
        break;
      }
      case 'object': {
        // @ts-ignore
        config[key] = createRoutes(base, value);
        break;
      }
    }

    return config;
  }, config);
};

export const signIn = `/sign-in`;
export const signUp = createRoutes(`/sign-up`, {
  send: '',
  confirm: '/confirm',
  setup: '/setup',
  redirect: '/redirect'
});
export const forgotPassword = createRoutes(`/forgot-password`, {
  send: '',
  reset: '/reset/:userId'
});

export const onboard = `/onboard`;

export const home = `/`;

export const account = createRoutes(`/account`, {
  root: '',
  user: createRoutes('/user', {
    root: '',
    profile: '/profile',
    password: '/password'
  }),
  organization: createRoutes('/organization', {
    settings: '/settings',
    one: createRoutes('/:organizationId', {
      subdomain: createRoutes('/subdomain', {
        root: '',
        one: (o: { organizationId: number }) => generatePath(account.organization.one.subdomain.root, o)
      })
    })
  }),
  tutor: createRoutes('/tutor', {
    profile: '/profile'
  }),
  teacher: createRoutes('/teacher', {
    root: '',
    link: '/link',
    school: createRoutes('/school', {
      root: '',
      benefits: '/benefits',
      setup: '/setup'
    })
  }),
  billing: createRoutes('/billing', {
    root: '',
    new: '/new',
    invoices: '/invoices'
  }),
  registration: createRoutes('/registration', {
    root: '',
    individual: createRoutes('/individual', {
      plans: createRoutes('/plans', {
        root: '',
        update: '/:subscriptionId?-:planId?-:quantity?',
        updateOne: (o: { subscriptionId: number; planId: string; quantity: number }) =>
          generatePath(account.registration.individual.plans.update, o),
        purchase: '/purchase/:planId',
        purchaseOne: (o: { planId: string }) => generatePath(account.registration.individual.plans.purchase, o)
      })
    }),
    agency: createRoutes('/agency', {
      plans: createRoutes('/plans', {
        root: '',
        update: '/:subscriptionId?-:planId?-:quantity?',
        updateOne: (o: { subscriptionId: number; planId: string; quantity: number }) =>
          generatePath(account.registration.agency.plans.update, o),
        purchase: '/purchase/:planId/:quantity',
        purchaseOne: (o: { planId: string; quantity: number }) =>
          generatePath(account.registration.agency.plans.purchase, o)
      }),
      join: '/join',
      one: '/one/:organizationId',
      getOne: (o: { organizationId: number }) => generatePath(account.registration.agency.one, o),
      invite: '/invite/:organizationId',
      inviteOne: (o: { organizationId: number }) => generatePath(account.registration.agency.invite, o)
    }),
    student: '/student',
    parent: '/parent',
    purchaseOne: (o: { planId: string; quantity: number; type: OrganizationType }) => {
      if (o.type === OrganizationType.Individual) return account.registration.individual.plans.purchaseOne(o);

      return account.registration.agency.plans.purchaseOne(o);
    },
    updateOne: (o: { subscriptionId: number; planId: string; quantity: number; type: OrganizationType }) => {
      if (o.type === OrganizationType.Individual) return account.registration.individual.plans.updateOne(o);

      return account.registration.agency.plans.updateOne(o);
    }
  }),
  link: '/link',
  brambleDirect: '/bramble-direct'
});

export const help = `https://help.bramble.io/hc/en-gb`;
export const search = `/smart-search`;

export const sessions = createRoutes('/sessions', {
  root: '',
  forChild: (childId: number) => `${sessions.root}?childId=${childId}`,
  one: `/:sessionId`,
  getOne: (o: { sessionId: number | string }) => generatePath(sessions.one, o),
  feedback: `/:sessionId/feedback`,
  getFeedback: (o: { sessionId: number | string }) => generatePath(sessions.feedback, o)
});

export const rooms = createRoutes('/rooms', {
  root: '',
  new: `/new`,
  getNew: (o: { organizationId: number | string }) => generatePath(`${rooms.new}?organization=:organizationId`, o),
  one: `/:roomId`,
  getOne: (o: { roomId: number | string }) => generatePath(rooms.one, o)
});

export const courses = createRoutes('/courses', {
  root: '',
  new: `/new`,
  one: `/:courseId`,
  getOne: (o: { courseId: number | string }) => generatePath(courses.one, o)
});

export const resources = '/resources';

export const integrations = createRoutes('/integrations', {
  root: '',
  issue: `/issue/:error`,
  consent: `/consent`
});

export const jobs = createRoutes('/jobs', {
  root: '',
  new: '/new',
  one: '/:jobId',
  getOne: (o: { jobId: number | string }) => generatePath(jobs.one, o)
});

export const tutors = createRoutes('/tutors', {
  root: ''
});

export const students = createRoutes('/students', {
  root: '',
  invite: createRoutes('/invite', {
    root: '',
    bulk: '/bulk'
  }),
  invoices: createRoutes('/invoices', {
    root: '',
    forStudent: (studentId: number) => `${students.invoices.root}?studentId=${studentId}`,
    create: '/new',
    createForStudent: (o: { studentId: number }) => `${students.invoices.create}?studentId=${o.studentId}`
  })
});

export const schedules = '/schedules';

export const agreements = createRoutes('/agreements', {
  [AgreementType.General]: `/${AgreementType.General}`,
  [AgreementType.BrambleDirect]: `/${AgreementType.BrambleDirect}`
});

export const legal = {
  privacyPolicy: 'https://about.bramble.io/trust/privacy-policy.html',
  termsAndConditions: 'https://about.bramble.io/trust/terms-and-conditions.html',
  brambleDirectTerms: {
    parent: 'https://about.bramble.io/trust/bramble-direct-pilot-parent-terms-and-conditions.html',
    tutor: 'https://about.bramble.io/trust/bramble-direct-pilot-tutor-terms-and-conditions.html'
  }
};

export const invoices = createRoutes('/invoices', {
  root: '',
  new: '/new'
});
