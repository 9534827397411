import classnames from 'classnames';
import * as React from 'react';

interface Props {
  visible: boolean;
  className?: string;
}

export const ValidationMessage: React.FC<Props> = ({ visible, className, ...rest }) => {
  if (!visible) return null;
  return <div className={classnames('text-sm text-red-500 capitalize-first', className)} role="alert" {...rest} />;
};
