import { api } from '@/service/network/api';
import { authorizedApi } from '@/service/network/authorized-api';

export class PasswordService {
  requestResetPasswordByEmail = (email: string) => {
    return api.post<null>(`/password/reset/send`, { email });
  };

  resetPasswordWithToken = (id: number, password: string, token: string) => {
    return api.post<null>(`/password/reset`, { id, token, password });
  };

  changePassword = (oldPassword: string, newPassword: string) => {
    return authorizedApi.post<null>(`/password`, { oldPassword, newPassword });
  };
}

export const passwordService = new PasswordService();
