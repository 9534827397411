export interface Subject {
  id: number;
  name: string;
  color: string;
  l0?: number;
  l1?: number;
  l2?: number;
  l3?: number;
  level: 0 | 1 | 2 | 3;
}

export type NextSubjectLevel = Exclude<Subject['level'], 0>;

export const subjectLevelNames: Record<NextSubjectLevel, string> = {
  1: 'subject',
  2: 'topic',
  3: 'subtopic'
};

export interface SubjectWithLevels {
  id: number;
  name: string;
  levels: number[];
}

export interface SubjectLevel {
  id: number;
  name: string;
}

export interface SubjectsLevels {
  subjects: SubjectWithLevels[];
  levels: SubjectLevel[];
}
