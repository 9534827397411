import * as React from 'react';

import { Brand, Configuration } from '@/model';

const initialConfiguration: Configuration = {
  brand: Brand.Bramble
};

interface ConfigurationState {
  readonly configuration: Configuration;
}

interface ConfigurationActions {
  update(configuration: Partial<Configuration>): any;
}

interface Props {}

interface State extends ConfigurationState, ConfigurationActions {}

export const ConfigurationContext = React.createContext<State>({
  configuration: initialConfiguration,
  update: () => null
});

export const ConfigurationConsumer = ConfigurationContext.Consumer;

export const ConfigurationProvider: React.FC<Props> = ({ children }) => {
  const [configuration, setConfiguration] = React.useState(() => initialConfiguration);

  const state = React.useMemo(
    (): State => ({
      configuration,
      update: (configuration) => setConfiguration((state) => ({ ...state, ...configuration }))
    }),
    [configuration, setConfiguration]
  );

  return <ConfigurationContext.Provider value={state}>{children}</ConfigurationContext.Provider>;
};

export default {
  ConfigurationProvider,
  ConfigurationConsumer,
  ConfigurationContext
};
