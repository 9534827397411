import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { FormApi, FORM_ERROR } from 'final-form';
import set from 'lodash/set';
import React from 'react';
import { Form as OriginalForm, FormProps, FormRenderProps } from 'react-final-form';
import * as yup from 'yup';

import { errors } from './errors';

export * from './FormField';

yup.setLocale(errors);

interface BackendError {
  status: number;
  message: string;
  fields?: {
    [x: string]: string;
  };
}

export type ErrorHandler<T> = (error: T) => string | object | undefined;

interface ErrorHandlerMap {
  [code: number]: ErrorHandler<BackendError>;
  default: ErrorHandler<Error | BackendError>;
}

export const handleError = (map: ErrorHandlerMap) => (error: AxiosError<BackendError>) => {
  const data = error.isAxiosError ? error.response?.data : undefined;
  const handler = map[data?.status ?? 0] || map.default;

  return data ? handler(data) || map.default(data) : map.default(error);
};

export type RenderProps<T> = FormRenderProps<T>;

export type Props<S extends yup.ObjectSchema<any>> = Omit<FormProps<yup.InferType<S>>, 'onSubmit'> & {
  initialValues?: Partial<yup.InferType<S>>;
  schema?: S;
  children(form: RenderProps<yup.InferType<S>>): React.ReactNode;
  onSubmit(
    values: yup.InferType<S>,
    form: FormApi
  ): Promise<Object | null | undefined | void> | Object | null | undefined | void;
};

export function Form<S extends yup.ObjectSchema<any>>({
  schema,
  children,
  ...rest
}: React.PropsWithChildren<Props<S>>) {
  const getValue = React.useCallback((values: yup.InferType<S>) => (schema ? schema.cast(values) : values), [schema]);

  const validate = React.useCallback(
    (values: yup.InferType<S>) => {
      if (!schema) return undefined;

      return schema
        .validate(values, { abortEarly: false, stripUnknown: true })
        .then(() => null)
        .catch((error: yup.ValidationError) => {
          return error.inner.reduce((fields, error) => {
            // let's pass both the error message (which is the key to localize) and
            // the params so it can be formatted where it's displayed
            set(fields, error.path!, { id: error.message, values: error.params as any });
            return fields;
          }, {} as { [x: string]: yup.ValidationError });
        });
    },
    [schema]
  );

  const onSubmit = React.useCallback(
    (values: yup.InferType<S>, form: FormApi): Promise<Object | null> => {
      return Promise.resolve()
        .then(() => rest.onSubmit(getValue(values), form))
        .catch(() => `It looks like something went wrong.`)
        .then((result: any) => {
          if (typeof result !== 'string') return result;

          return { [FORM_ERROR]: result };
        });
    },
    [rest.onSubmit, getValue]
  );

  return (
    <Sentry.ErrorBoundary>
      <OriginalForm {...rest} {...{ validate, onSubmit }} render={children} />
    </Sentry.ErrorBoundary>
  );
}
