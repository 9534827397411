import classnames from 'classnames';
import * as React from 'react';

interface Props {
  className?: string;
}

export const ButtonContainer: React.FC<Props> = ({ children, ...rest }) => (
  <div {...rest}>
    <div className="-m-1 flex">
      {React.Children.toArray(children).map((child) =>
        // @ts-ignore
        React.cloneElement(child as any, { className: classnames('m-1', child.props.className) })
      )}
    </div>
  </div>
);
