type GTag = (...args: any[]) => void;
type Fbq = (...args: any[]) => void;

interface GTagEvent {
  name: string;
  category: string;
  label?: string;
}

class Analytics {
  private gtag: Promise<GTag>;
  private fbq: Promise<Fbq>;

  constructor() {
    // @ts-ignore
    const gtag: GTag = window.gtag || ((...args) => console.log('Track GTM: ', ...args));
    this.gtag = Promise.resolve(gtag);

    // @ts-ignore
    const fbq: GTag = window.fbq || ((...args) => console.log('Track FBQ: ', ...args));
    this.fbq = Promise.resolve(fbq);
  }

  private run = (tracker: 'gtag' | 'fbq', callback: GTag) => this[tracker].then(callback).catch(() => null);

  trackPageView = () => {
    const uri = `${window.location.hostname}${window.location.pathname}${window.location.search}`;

    return Promise.all([
      this.run('gtag', (gtag) =>
        gtag('event', 'page_view', {
          page_title: document.title,
          page_location: `${window.location.protocol}//${uri}`,
          page_path: window.location.pathname
        })
      )
      // This is done automatically
      // this.run('fbq', (fbq) => fbq('track', 'PageView'))
    ]);
  };

  trackEvent = ({ name, category, label }: GTagEvent) => {
    return this.run('gtag', (gtag) =>
      gtag('event', name, {
        event_category: category,
        event_label: label
      })
    );
  };
}

export const analytics = new Analytics();
