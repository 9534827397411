import * as React from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';

import { generateRedirectUrl } from '@/component/Navigation/redirect';

interface Props extends RedirectProps {
  to: string;
}

export const RedirectWithPersist: React.FC<Props> = (props) => {
  const location = useLocation();

  return <Redirect {...props} to={generateRedirectUrl(`${location.pathname}${location.search}`, props.to)} />;
};
