import { authorizedApi } from '@/service/network/authorized-api';

export class ProfileService {
  update = async ({
    firstName,
    lastName,
    birthdate
  }: {
    firstName?: string;
    lastName?: string;
    birthdate?: string;
  }) => {
    return authorizedApi.post<null>(`/profile`, { firstName, lastName, birthdate });
  };
}

export const profileService = new ProfileService();
