import { parse, stringify } from 'query-string';
import url from 'url';

export const replaceQueryParams = (location: string, search: Object) => {
  const parsedUrl = url.parse(location);

  return url.format({ ...parsedUrl, search: stringify({ ...parse(parsedUrl.search || ''), ...search }) });
};

export const urlsHaveSameDomains = (one: string, two: string) => {
  const parsedOneUrl = url.parse(one);
  const parsedTwoUrl = url.parse(two);
  return parsedOneUrl.host === parsedTwoUrl.host;
};

export const generateRedirectUrl = (currentUrl: string, redirectUrl: string) => {
  return urlsHaveSameDomains(currentUrl, redirectUrl)
    ? replaceQueryParams(redirectUrl, { redirect: currentUrl })
    : replaceQueryParams(currentUrl, { redirect: null });
};

const sanitizeRedirectUrl = (url: string) => {
  /**
   * TODO: Fix unknown bug
   * User ends up on http://localhost:3000/sign-in?redirect=%2F%2Fschedules
   * and we will try to redirect them to //schedules, and that becomes
   * https://schedules, that is obviously invalid.
   *
   * We sanitize this input until we can't figure it out what is causing it.
   * So far it happened for a single user multiple times.
   *
   * Sentry link: https://sentry.io/organizations/bramble/issues/2719100614/
   */
  return url.replace(/^\/\//, '/');
};

export const getRedirectUrlFromParams = (path: string, search: string) => {
  const params = parse(search || '');

  const redirect = params.redirect
    ? typeof params.redirect === 'string'
      ? sanitizeRedirectUrl(params.redirect)
      : sanitizeRedirectUrl(params.redirect[0])
    : null;

  if (redirect && urlsHaveSameDomains(path, redirect)) return redirect;

  return null;
};
