class StorageService {
  constructor(private masterKey: string) {}

  private encode(data: any) {
    return JSON.stringify(data);
  }

  private decode(data: string) {
    return JSON.parse(data);
  }

  write(key: string, data: any) {
    try {
      if (data == null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, this.encode(data));
      }

      return data;
    } catch (exception) {
      return undefined;
    }
  }

  read(key: string) {
    try {
      const data = localStorage.getItem(key);

      if (data == null) {
        return undefined;
      }

      return this.decode(data);
    } catch (exception) {
      return undefined;
    }
  }

  setItem(key: string, data: any) {
    return this.write(`${this.masterKey}-${key}`, data);
  }

  getItem(key: string) {
    return this.read(`${this.masterKey}-${key}`);
  }
}

export const storageService = new StorageService('bramble');
