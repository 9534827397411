import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constant';

import { ConsumeRedirectRoute } from '@/component/Navigation/ConsumeRedirectRoute';

import { SignUpConfirmPage } from './SignUpConfirmPage';
import { SignUpSendPage } from './SignUpSendPage';
import { SignUpSetupPage } from './SignUpSetupPage';
import { SignUpSetupRedirectPage } from './SignUpSetupRedirectPage';

export const SignUpPage: React.FC = () => (
  <Switch>
    <Route exact path={urls.signUp.send} component={SignUpSendPage} />
    <Route exact path={urls.signUp.confirm} component={SignUpConfirmPage} />
    <Route exact path={urls.signUp.setup} component={SignUpSetupPage} />
    <Route exact path={urls.signUp.redirect} component={SignUpSetupRedirectPage} />
    <ConsumeRedirectRoute render={() => <Redirect to={urls.signUp.send} />} />
  </Switch>
);
