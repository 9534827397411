import cuid from 'cuid';

import { API } from '@/config';
import { tz } from '@/service/timezone';

import { HttpClient, HttpError, HttpRequestConfig } from './http';

enum Header {
  TraceId = 'X-Trace-Id',
  Timezone = 'X-Timezone'
}

export class ApiClient extends HttpClient {
  constructor(options: HttpRequestConfig) {
    super(options);

    this.client.interceptors.request.use(this.setConfiguration);
  }

  protected async setConfiguration(config: HttpRequestConfig) {
    const headers = config.headers || {};
    const traceId = headers[Header.TraceId] || `t-${cuid()}`;

    const timezone = await tz.get();

    return {
      ...config,
      headers: {
        ...headers,
        [Header.TraceId]: traceId,
        [Header.Timezone]: timezone
      }
    };
  }

  protected getReportOptions(error: HttpError<any>) {
    return {
      ...super.getReportOptions(error),
      traceId: error.config.headers?.[Header.TraceId]
    };
  }
}

export const api = new ApiClient({
  baseURL: API,
  headers: { 'Content-Type': 'application/json' }
});
