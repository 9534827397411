import { authorizedApi } from '@/service/network/authorized-api';
import { storageService } from '@/service/storage';

import { ACTION_SESSION_UPDATE, renewAccessToken, signOut } from './action';

export const apiAuthMiddleware = (store: any) => {
  authorizedApi.configure({
    onAccessTokenExpired: () => store.dispatch(renewAccessToken()),
    onUnauthorized: () => store.dispatch(signOut())
  });

  return (next: any) => (action: any) => {
    if (action.type === ACTION_SESSION_UPDATE) {
      authorizedApi.token = action.payload && action.payload.accessToken ? action.payload.accessToken : null;
      storageService.setItem('session', action.payload);
    }

    return next(action);
  };
};
