import querystring from 'query-string';
import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { urls } from '@/constant';

import { ButtonContainer } from '@/component/ButtonContainer';
import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { Button } from '@/component/UiKit/Button';
import { LinkWithPersist } from '@/component/UiKit/Link';

export const SignUpConfirmPage: React.FC = () => {
  const location = useLocation();

  const { email = null } = React.useMemo(() => (querystring.parse(location.search) as any) || {}, [location]);

  return (
    <IdentityPage
      title="Sign up"
      header={
        <Button is={LinkWithPersist} appearance="primary" to={urls.signIn}>
          Log In
        </Button>
      }
      contentClassName="space-y-6"
    >
      <IdentityTitle title="Sign up">We&apos;ve sent you an email</IdentityTitle>

      <div className="text-sm">
        Check your email inbox {!!email && `at ${email}`} and follow the instructions to create your account.
        <br />
        <br />
        It might take a few minutes for the email to arrive - please check your inbox and spam.
      </div>

      <ButtonContainer>
        <Button is={RouterLink} appearance="secondary" to={urls.signUp.send}>
          Still no email? Try again
        </Button>
      </ButtonContainer>
    </IdentityPage>
  );
};
