import moment from 'moment';

export const dateFromUTC = (date: Date) => moment(date).subtract(moment(date).utcOffset(), 'minute').toDate();

export const dateToUTC = (date: Date) => moment(date).add(moment(date).utcOffset(), 'minute').toDate();

export const formatDateRange = (from: Date, to: Date) => {
  const f = moment(from);
  const t = moment(to);

  if (f.year() != t.year()) return `${f.format('D MMM, YYYY')} - ${t.format(`D MMM, YYYY`)}`;

  if (f.month() != t.month()) return `${f.format('D MMM')} - ${t.format(`D MMM, YYYY`)}`;

  if (f.date() != t.date()) return `${f.format('D')} - ${t.format(`D MMM, YYYY`)}`;

  return `${f.format(`D MMM, YYYY`)}`;
};
