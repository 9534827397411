import classnames from 'classnames';
import * as React from 'react';

import { Is, IsComponent, Props as IsProps } from '@/component/Is';
import { Loading, LoadingIndicator } from '@/component/UiKit/Loading';

export type ButtonAppearance = 'primary' | 'secondary';

export type Props<T extends IsComponent> = Omit<IsProps<T>, 'size'> & {
  appearance?: ButtonAppearance;
  loading?: boolean;
  contentClassName?: string;
};

const appearances: Record<ButtonAppearance, string> = {
  primary:
    'text-white border-transparent bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:bg-gray-400',
  secondary:
    'text-gray-900 border-gray-200 bg-gray-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
};

function ButtonComponent<T extends IsComponent = 'button'>(
  {
    is = 'button',
    appearance,
    loading,
    disabled,
    className,
    contentClassName,
    children,
    ...rest
  }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) {
  return (
    <Is
      className={classnames(
        'relative inline-flex h-10 items-center rounded border border-solid px-6 text-center text-sm font-medium no-underline',
        !!appearance && appearances[appearance],
        className
      )}
      disabled={disabled || loading}
      {...rest}
      {...{ ref, is }}
    >
      <Loading visible={!!loading} className="absolute inset-0 flex items-center justify-center">
        <LoadingIndicator className="h-5 w-5" />
      </Loading>

      <div className={classnames(contentClassName, { 'opacity-0': loading })}>{children}</div>
    </Is>
  );
}

export const Button = React.forwardRef(ButtonComponent);
