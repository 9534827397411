import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { Spacer } from '@/component/Spacer';

export const IntegrationIssuePage: React.FC = () => {
  const {
    params: { error }
  } = useRouteMatch<{ error: string }>();

  if (error.startsWith('tc-no-consent')) {
    return (
      <IdentityPage title="Integration" contentClassName="space-y-6">
        <IdentityTitle title="Integration not yet completed">Integration not yet completed</IdentityTitle>

        <div>
          Please contact your administrator and ask them to follow the instructions we sent to him via email to complete
          the integration.
        </div>
      </IdentityPage>
    );
  }

  if (error.startsWith('tc-waiting-consent')) {
    return (
      <IdentityPage title="Integration" contentClassName="space-y-6">
        <IdentityTitle title="Finish integration">Finish integration</IdentityTitle>

        <div>Please follow the instructions we&apos;ve sent to you via email to complete the integration.</div>
      </IdentityPage>
    );
  }

  return (
    <IdentityPage title="Integration" contentClassName="space-y-6">
      <IdentityTitle title="Something went wrong">Something went wrong</IdentityTitle>

      <div>
        We are sorry, but we couldn&apos;t handle your request. <pre>(reference: {error})</pre>
      </div>

      <Spacer bottom={3} />

      <div>
        If the error persists please contact us at{' '}
        <a href="mailto:help@bramble.io" className="underline">
          help@bramble.io
        </a>
        .
      </div>
    </IdentityPage>
  );
};
