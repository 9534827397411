export enum SubscriptionType {
  Monthly = 'month',
  Yearly = 'year'
}

export enum SubscriptionStatus {
  Trialing = 'TRIALING',
  Active = 'ACTIVE',
  PastDue = 'PAST_DUE',
  Canceled = 'CANCELED',
  Unpaid = 'UNPAID',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED'
}

export interface Subscription {
  id: number;
  name: string;
  planId: string;
  quantity: number;
  type: SubscriptionType;
  interval: number;
  organizationId: number;
  currency: string;
  amount: number;
  valid: boolean;
  willBeCanceled: boolean;
  status: SubscriptionStatus;
  expireAt: Date;
}
