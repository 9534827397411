import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { urls } from '@/constant';
import { passwordService } from '@/service/business/password';

import { ButtonContainer } from '@/component/ButtonContainer';
import { Form, FormField, handleError } from '@/component/Form';
import { IdentityPage, IdentityTitle } from '@/component/IdentityPage';
import { Button } from '@/component/UiKit/Button';
import { Label } from '@/component/UiKit/Field/Label';
import { Link, LinkWithPersist } from '@/component/UiKit/Link';
import { TextInput } from '@/component/UiKit/TextInput';
import { ValidationMessage } from '@/component/UiKit/ValidationMessage';

const schema = yup
  .object({
    email: yup.string().email().required()
  })
  .required();

export const ForgotPasswordRequestPage: React.FC = () => {
  const onSubmit = React.useCallback((values: yup.InferType<typeof schema>) => {
    return passwordService
      .requestResetPasswordByEmail(values.email)
      .then(() => null)
      .catch(
        handleError({
          [400]: (error) => error.fields,
          default: () => `We couldn't send you the email. Please try again later.`
        })
      );
  }, []);

  return (
    <IdentityPage
      title="Forgot password"
      header={
        <Button is={RouterLink} appearance="primary" to={urls.signIn}>
          Log In
        </Button>
      }
    >
      <Form {...{ schema, onSubmit }}>
        {({ submitError, submitting, submitSucceeded, handleSubmit, form }) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <IdentityTitle title="Forgot password">Forgot Password</IdentityTitle>

            {submitSucceeded ? (
              <React.Fragment>
                <div className="text-sm">
                  Check your email inbox and follow the instructions.
                  <br />
                  <br />
                  Might take a few minutes for the email to arrive. Check both your inbox and spam.
                  <br />
                  <br />
                  Still no email?
                </div>

                <ButtonContainer>
                  <Button appearance="secondary" onClick={() => form.reset()}>
                    Send another reset email
                  </Button>
                </ButtonContainer>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="space-y-4">
                  <div className="space-y-1">
                    <Label htmlFor="email">Email address</Label>

                    <FormField
                      is={TextInput}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="nick@email.com"
                      readOnly={submitting}
                      validated
                    />
                  </div>

                  <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

                  <ButtonContainer>
                    <Button appearance="primary" type="submit" loading={submitting}>
                      Reset Password
                    </Button>
                  </ButtonContainer>
                </div>

                <div>
                  <Link is={LinkWithPersist} to={urls.signIn} className="text-sm">
                    Remember your password? Log in
                  </Link>
                </div>
              </React.Fragment>
            )}
          </form>
        )}
      </Form>
    </IdentityPage>
  );
};
