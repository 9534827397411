import * as React from 'react';
import { useLocation } from 'react-router';

import { analytics } from '@/service/analytics';

export const PageViewTracker: React.FC = () => {
  const router = useLocation();

  React.useEffect(() => {
    const timeout = setTimeout(() => analytics.trackPageView());
    return () => clearTimeout(timeout);
  }, [router]);

  return null;
};
