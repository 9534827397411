import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ENVIRONMENT, SENTRY_DSN, VERSION } from '@/config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: VERSION,
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 10,
  autoSessionTracking: true
});

export * from '@sentry/react';
