import * as React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import { generateRedirectUrl, getRedirectUrlFromParams } from '@/component/Navigation/redirect';

interface Props extends LinkProps {
  to: string;
}

export const LinkWithPersist: React.FC<Props> = ({ to, ...rest }) => {
  const location = useLocation();

  const redirect = getRedirectUrlFromParams(location.pathname, location.search);
  const url = redirect ? generateRedirectUrl(redirect, to) : to;

  return <Link to={url} {...rest} />;
};
