import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { urls } from '@/constant';
import * as context from '@/redux/context';
import * as session from '@/redux/session';

import { generateRedirectUrl, getRedirectUrlFromParams } from '@/component/Navigation/redirect';

export const SignUpSetupRedirectPage: React.FC = () => {
  const location = useLocation();

  const authenticated = useSelector(session.getRefreshToken);
  const userContext = useSelector(context.get);
  const hasTutoringProgram = useSelector(context.hasTutoringProgram);
  const isTeacher = useSelector(context.isTeacher);
  const isTutor = useSelector(context.isTutor);

  if (!authenticated) return <Redirect to={urls.signUp.send} />;

  if (!userContext) return null;

  let redirect = getRedirectUrlFromParams(location.pathname, location.search);

  if (redirect) return <Redirect to={redirect} />;

  redirect = hasTutoringProgram
    ? isTeacher
      ? urls.account.teacher.school.root
      : isTutor
      ? generateRedirectUrl(urls.jobs.root, urls.account.tutor.profile)
      : urls.onboard
    : isTutor
    ? urls.rooms.root
    : urls.onboard;

  return <Redirect to={redirect} />;
};
